<template>
  <div class="notification-container" v-if="$route.name !== 'dash'">
    <div class="noti-title">
      <span>{{ localTime }}</span>
      <page-title />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/main/PageTitle";

export default {
  name: "BreadCrumb",
  props: ["localTime"],
  components: {
    PageTitle
  },
  data() {
    return {
      siteInfo: ""
    };
  },
  methods: {
  },
  created() {
    this.siteInfo = this.getSiteInfo();
  }
};
</script>

<style scoped>
.notification-container {
  display: flex;
  align-items: center;
  /* width: 100%; */
  min-width: 1600px;
  justify-content: space-between;
  padding-right: 17px;
  box-sizing: border-box;
  margin: 15px 0 12px 17px;
}
.notification-wrap {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
}
.noti-title {
  font-size: 13px;
  color: #ededed;
  font-weight: 800;
  margin-right: 10px;
}
.noti-title span {
  white-space: nowrap;
}
.noti-title span:last-child {
  margin-left: 5px;
}
.noti-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 6px;
  height: 25px;
  border-radius: 3px;
  color: #666;
  margin-right: 3px;
  user-select: none;
  border: 1px solid #bfbfbf;
}
.noti-item a {
  color: #666;
}
.noti-item a span span {
  color: #e95d50;
  font-weight: bold;
}
.noti-item a:first-child span span {
  color: #18698b;
}
.noti-item .text {
  margin-right: 2px;
}
.title-box {
  margin-right: 10px;
}
.contents-box {
  margin-right: 6px;
}
.contents-box.title {
  font-weight: 700;
}
.contents-box:last-child {
  margin: 0;
}

.content-mono {
  margin-right: 4px;
}
a {
  color: #fff;
}
a:hover {
  font-weight: 800;
}
</style>
