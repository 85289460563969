<template>
  <section class="pageName">
    <h3 class="sup">{{ $t(parentText) }}</h3>
    <i class="fas fa-angle-double-right"></i>
    <h3 class="sub">{{ $t(childText) }}</h3>
    <i class="fas fa-angle-double-right" v-if="thirdText"></i>
    <h3 class="sub">{{ $t(thirdText) }}</h3>
    <template v-if="gameTitle">
      <i class="fas fa-angle-double-right"></i>
      <h3 class="sub">{{ gameTitle }}</h3>
    </template>

  </section>
</template>

<script>
export default {
  name: 'PageTitle',
  data () {
    return {
      parentText: '',
      childText: '',
      thirdText: '',
      gameTitle: ''
    }
  },
  watch: {
    $i18n () {
      this.getCurrentMenu()
    },
    $route: {
      handler () {
        // console.log('pageTitle : ', this.$route)
        if (this.$route.fullPath !== '/login') {
          this.getCurrentMenu()
        }
      }
    }
  },
  created () {
    setTimeout(() => {
      this.getCurrentMenu()

      this.emitter.on('gameTitle', async (data) => {
        if (data) {
          this.gameTitle = data
        }
      })
    }, 500)
  },
  beforeRouteLeave () {
    this.gameTitle = ''
  },
  methods: {
    getCurrentMenu () {
      const parentMenu = this.$route.matched[1].meta
      const thirdMenu = this.$route.matched[3] ? this.$route.matched[3].meta : {}

      this.parentText = parentMenu.text
      this.childText = this.$route.meta.text
      this.thirdText = thirdMenu.text || ''

      document.title = this.parentText + '>' + this.childText + '>' + this.thirdText
    }
  }
}
</script>

<style scoped>
.pageName {
  display: flex;
  align-items: center;
}
h2 {
}
.pageName i {
  margin: 0 10px;
  color: #353535;
}
h3 {
   font-size: 16px;
   color: #061738;
   font-weight: normal;
   text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
}
</style>
