<template>
  <bread-crumb/>
  <iframe src="/silence.mp3" allow="" id="audio" style="display:none" />
  <router-view :key="$route.fullPath"/>
</template>

<script>
import { getAuthToken, removeAuthData, setAuthToken, setLocale, getSiteData } from '@/libs/auth-helper'
import BreadCrumb from '@/components/main/header/BreadCrumb'
import store from '@/store'
export default {
  name: 'TheMember',
  components: {
    BreadCrumb
  },
  methods: {
    loadGameGroupCode () {
      store.dispatch('storeGameGroupCodes')
    }
  },
  async created () {
    await this.$store.dispatch('storeSiteData')
    await this.loadGameGroupCode()
  },
  beforeRouteEnter (to, from, next) {
    const token = getAuthToken()
    const siteInfo = getSiteData()
    // console.log(token)
    if (token && siteInfo) {
      next()
    } else {
      removeAuthData()
      next('/login')
    }
  }
}
</script>

<style scoped>
.page-container {
  padding: 15px 15px;
  position: relative;
}
</style>
